// const {
//     collapseTextChangeRangesAcrossMultipleVersions,
// } = require("typescript");

jQuery(document).ready(function ($) {
    var numberOfWidgets = 6;
    var totalWidgetLoaded = 0;
    var enabledWidgets = 0;
    //Count with image widgets
    var imgTileCount = $(".img-title").length;

    window.widgetLoaded = function (show, widgetClass) {
        if (widgetClass !== "actionListWidget") {
            totalWidgetLoaded++;
        } else {
            //case for ActionList
            if (!show) {
                console.log("Displaying/hiding ActionList");
                $("." + widgetClass).removeClass("style");
                var actionsDiv = $("ActionsMenu");
                actionsDiv.hide();
            }
        }

        if (!show) {
            $("." + widgetClass).removeClass("col-md");
            $("." + widgetClass).removeAttr("style");
        } else {
            if (widgetClass !== "actionListWidget") {
                enabledWidgets++;
            }
        }

        if (totalWidgetLoaded == numberOfWidgets) {
            $("#mainWidgetContainer").removeClass("d-none");
            if (enabledWidgets == 4) {
                $(".container-lg .row .col-md").attr("style", "min-width: 50%");
                // $("#dnn_DoublePaneOneTree").attr("style", "min-width: 50%");
            }
            
            if (imgTileCount < 3) {
                responsiveMainWidgets();
            } else {
                responsiveLinkList();
            }
        }
    };

    //dynamic link list widgets responsiveness
    $(window).on("resize", function (){
        //Compute max height of link list widgets
        if (imgTileCount < 3) {
            responsiveMainWidgets();
        } else {
            responsiveLinkList();
        }
    });

     function responsiveLinkList() {
        var maxHeight = Math.max.apply(null, $(".tile.link-list").map(function (){
            return $(this).outerHeight();
          }).get());
    
        if (window.matchMedia("(min-width: 768px)").matches) {
            $(".tile.link-list").css("min-height", maxHeight);
            $("#actionsMenu .link-list").css("min-height", "");
        }
        else {
            $(".tile.link-list").css("min-height", "");
        }
    }

    function responsiveMainWidgets() {
        var maxHeight = Math.max.apply(null, $(".widget-container, .tile.link-list").map(function (){
            return $(this).outerHeight();
          }).get());
    
        if (window.matchMedia("(min-width: 768px)").matches) {
            $(".tile.link-list").css("min-height", maxHeight);
            $(".img-tile").css("min-height", maxHeight);
            $("#actionsMenu .link-list").css("min-height", "");
        }
        else {
            $(".tile.link-list").css("min-height", "");
            $(".widget-container").css("min-height", "");
        }
    }

    //banner slider js is already on index.html
    window.bannerDataLoaded = function (isCenterMode) {
        if (isCenterMode === undefined) {
            isCenterMode = true;
        }

        $("#banner-slider").slick({
            //normal options
            mobileFirst: true,
            accessibility: true,
            autoplay: false,
            arrows: false,
            centerMode: isCenterMode,
            centerPadding: "20px",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,

            responsive: [{
                //breakpoint up
                breakpoint: 767, //bootstrap breakpoint for md
                settings: {
                    autoplay: true, //true with pause/play ***NEED TO ADD PAUSE/PLAY
                    arrows: true,
                    centerMode: false,
                    dots: true,
                },
            },],
        });

        // toggle pause/play
        $(".sliderPlayPause").click(function () {
            if ($(".sliderPlayPause").hasClass("play")) {
                $("#banner-slider").slick("slickPlay");
                $(".sliderPlayPause").removeClass("play");
            } else {
                $("#banner-slider").slick("slickPause");
                $(".sliderPlayPause").addClass("play");
            }
        });
        $(".sliderPlayPause").keydown(function (e) {
            var code = e.which;
            // 13 = Return, 32 = Space
            if (code === 13 || code === 32) {
                $(this).click();
            }
        })
    };
    //mobile menu behavior
    // toggle icon
    // $('.navbar-toggle-icon').click(function () {
    //     $(this).children('svg').toggleClass('d-none');
    // });
    
});



